<template>
    <div class="sidebar">
        <div class="sidebar-bg" @click="closeSideBar"></div>
        <div class="sidebar-left">
            <div class="sidebar-title">
                <div class="sidebar-name">{{info.account}}</div>
                <div class="sidebar-company">ID：{{info.code}}</div>
            </div>
            <div class="sidebar-center">
                <div class="sidebar-center-all" @click="into_auth">
                    <img class="sidebar-center-img" style="padding-left: 3rpx;" src="@/assets/icon/sidebar-sm.png" alt=""/>
                    <div class="sidebar-center-text">实名认证</div>
                </div>
                <div class="sidebar-center-b sidebar-center-all" @click="into_notice">
                    <img class="sidebar-center-img" src="@/assets/icon/sidebar-tz.png" alt=""/>
                    <div class="sidebar-center-text">系统通知</div>
                </div>
                <div class="sidebar-center-c sidebar-center-all" @click="into_secure">
                    <img class="sidebar-center-img" src="@/assets/icon/sidebar-zh.png" alt=""/>
                    <div class="sidebar-center-text">账户安全</div>
                </div>
                <div class="sidebar-center-c sidebar-center-all">
                    <img class="sidebar-center-img" src="@/assets/icon/sidebar-lx.png" alt=""/>
                    <div class="sidebar-center-text"><a href="tel:4000362988">联系我们</a></div>
                </div>
            </div>
            <div class="sidebar-signout" @click="sign_out">
                <div class="signout-icon"><i class="el-icon-switch-button"></i></div>
                <!-- <img class="sidebar-center-img" src="@/assets/icon/sidebar-tuichu.png" alt=""/> -->
                <div class="sidebar-center-text">退出</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name:"sideBar",
    data () {
        return {
            info:'',
            popup_if: false
        }
    },
    created(){
        this.info = JSON.parse(localStorage.getItem('info'));
    },
    methods: {
        /**
         * 隐藏主页面侧边栏
         */ 
        closeSideBar () {
            this.$emit('closeSideBar')
        },
        // 实名认证
        into_auth () {
            if( this.info.validate=="pass"){
                this.$router.push({name:'authentication'})
            }else{
                this.$router.push({name:'auth'})
            }
        },
        // 系统通知
        into_notice () {
            this.$router.push({name:'notice'})
        },
        // 账号安全
        into_secure () {
            this.$router.push({name:'secure'})
        },
        // 退出
        sign_out () {
            this.$confirm(<div><i class="el-icon-warning" style="color:#e6a23c;font-size:18px;margin-right:4px"></i>确定要退出吗？</div>, '', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                customClass:"width-box",
                showClose:false,
                center: true
                }).then(() => {
                    let params ={
                    token:localStorage.getItem('mu_token')
                    } 
                    this.$store.dispatch('auth/logout',{params:params}).then(() => {
                        this.$router.replace({name:'login'})
                    })
                }).catch(() => {});
        },
        /**
         * 退出弹出层-取消
         */
        popup_cancel () {
            this.popup_if = false
        },
        /**
         * 退出弹出层-确定
         */
        // popup_determine () {
        //     this.popup_if = false
        //     this.$emit('nav_none', false)
        //     let url
        //     // #ifdef H5
        //     url = 'LOGINOUT'
        //     // #endif
        //     // #ifdef MP-WEIXIN
        //     url = 'WXLOGINOUT'
        //     // #endif
        //     this.$store.dispatch(url).then((res) => {
        //         let channel = uni.getStorageSync('channel')
        //         if (channel === 'longbridge') {
        //             uni.clearStorageSync()
        //             uni.reLaunch({
        //                 url: '/pages/login/login?channel=longbridge'
        //             })
        //         } else {
        //             uni.clearStorageSync()
        //             uni.reLaunch({
        //                 url: '/pages/login/login'
        //             })
        //         }
        //     }).catch((Err) => {
        //         let channel = uni.getStorageSync('channel')
        //         if (channel === 'longbridge') {
        //             uni.clearStorageSync()
        //             uni.reLaunch({
        //                 url: '/pages/login/login?channel=longbridge'
        //             })
        //         } else {
        //             uni.clearStorageSync()
        //             uni.reLaunch({
        //                 url: '/pages/login/login'
        //             })
        //         }
        //     })
        // }
    }
}
</script>
<style scoped lang='scss'>
.sidebar{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 11;
    .sidebar-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000000;
        opacity: 0.44;
    }
    .sidebar-left{
        width: 11rem;
        height: 100%;
        opacity: 0.83;
        background: #000000;
        padding-left: 1rem;
        padding-top: 2rem;
        .sidebar-title{
            margin-bottom:2rem;
            .sidebar-name{
                font-family: PingFangSC-Regular;
                font-size: 15px;
                color: #FFFFFF;
                letter-spacing: 0;
                text-align: left;
            }
            .sidebar-company{
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #8E8E8E;
                letter-spacing: 0;
                line-height: 24px;
                text-align: left;
            }
        }
        .sidebar-center{
            .sidebar-center-all{
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #FFFFFF;
                letter-spacing: 0;
                margin-bottom: 1rem;
                display: flex;
                align-items: center;
            }
            .sidebar-center-img{
                width: 1.5rem;
                height: 1.5rem;
                margin-right:.4rem;
            }
            .sidebar-center-text{
                line-height: 2rem;
            }
        }
        .sidebar-signout{
            position: absolute;
            bottom: 2rem;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #FFFFFF;
            letter-spacing: 0;
            line-height: 14px;
            display: flex;
            align-items: center;
            .sidebar-center-img{
                width: 1.5rem;
                height: 1.5rem;
                margin-right:.4rem;
            }
            .sidebar-center-text{
                line-height: 2rem;
            }
            .signout-icon{
                width: 24px;
                height: 24px;
                font-size: 1.2rem;
                color: #000000;
                background: #ffffff;
                border-radius:50%;
                text-align: center;
                line-height: 24px;
                margin-right: .4rem;
            }
        }
    }
    .sidebar-popup{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .sidebar-popup-bg{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: #000000;
            opacity: 0.7;
        }
        .sidebar-popup-content{
            width: 450rpx;
            height: 250rpx;
            background: #ffffff;
            border-radius: 20rpx;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            font-size: 14px;
            font-family: PingFangSC-Regular;
            letter-spacing: 0;
            .popup-top{
                height: 150rpx;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                text-align: center;
            }
            .popup-but{
                height: 100rpx;
                border-top: 1px solid #dddd;
                display: flex;
                .popup-cancel, .popup-determine{
                    flex: 1;
                    line-height: 100rpx;
                    color: #375EC0;
                    text-align: center;
                }
                .solid{
                    width: 1px;
                    height: 100rpx;
                    background: #dddd;
                }
            }
        }
    }
}
</style>
