<template>
<div class="home">
  <div class="top-content">
    <div class="top-top flex-box">
      <div class="top-left"><img src="@/assets/img/mu-logo.png"/></div>
      <div class="top-right">
        <el-badge :is-dot="dot>0" class="item"><img @click="tongBtn" src="@/assets/img/home/msg.png"/></el-badge>
      </div>
    </div>
    <div class="box-items hold-box flex-box" @click="holdRouter">
      <div class="flex-box hold-left">
        <div><img :src="require('@/assets/img/home/sf.png')"/></div>
        <div class="theme-color">我的持有</div>
      </div>
      <div class="hold-right theme-color">查看详情 ></div>
    </div>
    
    <!-- <div class="banner-box theme-bgcolor">
      <img src="@/assets/img/header.png"/>
      <div class="logo">
        <img src="@/assets/img/logo.png"/>
      </div>
      <div>香港保险专家</div>
      <div class="sub-title">甄选优质保险，为您量身定制</div>
    </div> -->
    <!-- <div class="box-border flex-box items">
      <div  v-for="item in itemsTitle" :key="item.icon">
        <img :src="require('@/assets/img/'+item.icon+'.png')"/>
        <div>{{item.name}}</div>
      </div>
    </div> -->

    <div class="title">定期理财</div>
    <div class="box-items box-border" @click="stagesBuy">
      <div class="flex-box dqlc-top">
        <div class="dqlc-top-title">亚洲分期</div>
        <div class="dqlc-top-subtitle">精选优质消费类资产</div>
        <img class="money-icon" src="@/assets/img/home/money.png"/>
        <div class="money-en">$</div>
      </div>
     <div class="flex-box  dqlc-middle">
      <div style="margin-right:10%">
        <div class="box-num flex-box">
          <div class="flex-box box-percent">
            <div>{{ stagesMin }}</div>
            <div class="percent">%</div>
          </div>
          <div>-</div>
          <div class="flex-box box-percent">
            <div>{{ stagesMax }}</div>
            <div  class="percent">%</div>
          </div>
        </div>
        <div class="box-subnum">预期年化收益</div>
      </div>
      <div>
        <div class="box-num flex-box">
          <div class="flex-box box-percent">
            <div style="color:#000">$1,000</div>
            <div style="color:#fff">%</div>
          </div>
        </div>
        <div class="box-subnum">起投金额</div>
      </div>
     </div>
      <div class="flex-box flex-between dqlc-bottom">
        <div class="low-box flex-box" style="width:60%">
          <img  src="@/assets/img/home/low.png"/>
          <div >中低风险</div>
        </div>
        <div class="buy-btn flex-box">
          <img src="@/assets/img/home/buy.png"/>
          <div>立即购买</div>
        </div>
      </div>
    </div>
    <div class="insure-warp">
      <div class="contrast theme-color" @click="InsuranceComparison">险种对比 ></div>
      <div class="title">香港保险</div>
      <div class="insure-content box-border">
        <el-tabs v-model="activeName" @tab-click="filterType">
          <el-tab-pane label="全部" name="all"></el-tab-pane>
          <el-tab-pane label="重疾险" name="zjx"></el-tab-pane>
          <el-tab-pane label="储蓄险" name="cxx"></el-tab-pane>
        </el-tabs>
        <div class="box-items-line toright" :class="item.type=='重疾险'?'toright-zjx':''" v-for="item in bxtypesList" :key="item.id" @click="bxMore(item)">
          <div class="box-bs">{{item.type}}</div>
          <div class="flex-box"> 
            <div class="box-left-logo">
              <div class="box-img-logo">
                <img :src="require('@/assets/img/'+item.subtitle+'.png')"/>
              </div>
            </div>
            <div>
              <div class="box-title" style="margin-top:5px">{{item.title}}</div>
              <div class="box-subtitle">{{item.subtitle}}</div>
            </div>
          </div>
          <div class="box-more theme-color">查看详情 ></div>
        </div>
      </div>
    </div>
   
    <div class="eam-box"> 
      <div class="title">EAM私人银行服务</div>
      <div class="eam-banner" @click="insureDetail">
        <div class="box-title">私行中的“高级定制”</div>
        <div class="box-subtitle">一站式私人财富管理</div>
        <div class="insure-more">查看详情 ></div>
      </div>
    </div>
  </div>
   <div ref="navBtnMain"
        class="menuIcon"  
        :style="'left:' + moveX + 'px' + ';top:' + moveY + 'px'"
        :class="{ donghua: donghua }"
        @click="SidebarShow = true"
        @touchstart="drag_start"
        @touchmove.prevent="drag_hmove"
        @touchend="drag_end"></div>
    <Sidebar v-if="SidebarShow" @closeSideBar="SidebarShow=false"></Sidebar>
     <!-- <Footer :active="'market'" /> -->
 </div>

</template>
<script>
// 侧边栏
import Sidebar from '../components/sideBar'
// import Footer from '../components/Footer'
export default {
  components: {
    Sidebar,
    // Footer,
   
  },
data(){
  return{
    dot:0,
    activeName:"all",
    donghua:false,
    moveY: 250,
    moveX: 0,
    start: [0, 0],
    windowWidth: '',
    windowHeight: '',
    offsetWidth: '',
    offsetHeight: '',
    SidebarShow:false,
    stagesMin:'',
    stagesMax:'',
    itemsTitle:[{
      name:"家族信托",
      icon:"1"
    },{
      name:"私行服务",
      icon:"2"
    },{
      name:"移民服务",
      icon:"3"
    },{
      name:"其它服务",
      icon:"4"
    }],
    bxtypes:[{
      id:"3",
      title:"危疾家康保",
      type:"重疾险",
      subtitle:"永明"
    },{
      id:"4",
      title:"爱伴航",
      type:"重疾险",
      subtitle:"AIA"
    },{
      id:"5",
      title:"富通守护168加强版",
      type:"重疾险",
      subtitle:"富通"
    },{
      id:"6",
      title:"活耀人生危疾保2",
      type:"重疾险",
      subtitle:"宏利"
    },{
      id:"1",
      title:"万年青-传承",
      type:"储蓄险",
      subtitle:"永明"
    }],
    bxtypesList:[]
  }
},
mounted () {
  this.bxtypesList = this.bxtypes
  this.windowWidth = document.documentElement.clientWidth
  this.windowHeight = document.documentElement.clientHeight
  this.offsetWidth = this.$refs.navBtnMain.offsetWidth
  this.offsetHeight = this.$refs.navBtnMain.offsetHeight
  this.moveX = this.windowWidth - this.offsetWidth
},
created(){
  this.getInfo()
  this.getStages()
},
methods: {
  getInfo(){
    this.$store.dispatch('transation/getUserAmount',{params:{code:'my',type:'info'}}).then((res)=>{
      this.dot = res.info.msgcount
    })
  },
  getStages(){
    this.$store.dispatch('stages/getIncome',{params:{product:'akulaku'}}).then(res => {
      res.terms.forEach(element => {
        if(element.term==3){
          this.stagesMin=element.rate
        }
        if(element.term==12){
          this.stagesMax=element.rate
        }
      });
    })
  },
  // 立即购买
  stagesBuy(){
    this.$router.push({
      name:'stages'
    })
  },
  // 保险类别过滤
  filterType(){
   if(this.activeName=='all'){
       this.bxtypesList =this.bxtypes
   }else if(this.activeName=='zjx'){
    this.bxtypesList =this.bxtypes.filter(function(item){
      if(item.type=="重疾险"){
        return item
      }
    })
   }else if(this.activeName=='cxx'){
      this.bxtypesList =this.bxtypes.filter(function(item){
      if(item.type=="储蓄险"){
        return item
      }
    })
   }
  },
  // 保险查看详情
  bxMore(item){
    this.$router.push({
      path:"financingtpes",
      query:{
        name:item.title,
        company:item.subtitle
      }
    })
  },
  // EAM查看详情
  insureDetail(){
    this.$router.push({
      name:"insureDetail"
    })
  },
  // 我的持有
  holdRouter(){
    this.$router.push({
      name:"my"
    })
  },
  // 通知消息
  tongBtn(){
    this.$router.push({
      name:"notice"
    })
  },
  // 险种对比
  InsuranceComparison(){
    this.$router.push({
      name:"insuranceComparison"
    })
  },
  drag_start (event) {
    this.donghua = false
    this.start[0] = event.touches[0].clientX - event.target.offsetLeft
    this.start[1] = event.touches[0].clientY - event.target.offsetTop
  },
  drag_end (event) {
    this.donghua = true
    var windowWidth = this.windowWidth / 2
    var offsetWidth = this.offsetWidth / 2
    if (event.target.offsetLeft + offsetWidth <= windowWidth) {
      this.moveX = 0
    } else {
      this.moveX = this.windowWidth - this.offsetWidth
    }
  },
  drag_hmove (event) {
    let tag = event.touches
    if (tag[0].clientX < 0) {
      tag[0].clientX = 0
    }
    if (tag[0].clientY < 0) {
      tag[0].clientY = 0
    }
    if (tag[0].clientX > this.windowWidth) {
      tag[0].clientX = this.windowWidth
    }
    if (tag[0].clientY > this.windowHeight) {
      tag[0].clientY = this.windowHeight
    }

    if (tag[0].clientX - this.start[0] < 0) {
      this.moveX = 0
    } else if (tag[0].clientX > this.windowWidth - this.offsetWidth) {
      this.moveX = this.windowWidth - this.offsetWidth
    } else {
      this.moveX = tag[0].clientX - this.start[0]
    }

    if (tag[0].clientY - this.start[1] < 0) {
      this.moveY = 0
    } else if (tag[0].clientY > this.windowHeight - this.offsetHeight) {
      this.moveY = this.windowHeight - this.offsetHeight
    } else {
      this.moveY = tag[0].clientY - this.start[1]
    }
  }
}
}
</script>
<style  lang="scss" scoped>

.home{
  position: relative;
  .banner-box{
    height: 219px;
    color: #fff;
    position: relative;
    font-family: PingFangSC-Semibold, PingFang SC;
    padding:2.5% 3% 0%;
    line-height: 2;
    font-size: 1.2rem;
    overflow: hidden;
    >img{
      position: absolute;
      right: 0px;
      width: 65%;
      margin-top:-30px;
    }
    .logo{
      width: 100px;
      margin-bottom:20px;
      >img{
        width: 100%;
      }
    }
    .sub-title{
      font-size: .8rem;
      font-weight: 400;
      line-height: 1;
      font-family: PingFangSC-Regular, PingFang SC;
    }
  }
  .menuIcon {
    width: 3rem;
    height: 3rem;
    position: absolute;
    background: url("../assets/icon/menu_icon.png") no-repeat center;
    background-size: 100% 100%;
    z-index: 10;
    border-radius: 50%;
  }

}
.donghua {
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -o-transition: all 0.8s;
}

.el-carousel__item img {
   width:100%
}

.items{
  margin-top:-40px;
  position: relative;
  z-index:2;
  justify-content:space-between;
  font-size: .9rem;
  text-align: center;
  padding: 10px 10px;
  >div{
    padding:5px 10px;
    box-shadow: 0px 0px 4px 0px rgba(217,217,217,0.5);
    border-radius:8px;
    color: #464646;
    font-size: .9rem;
    >img{
      margin:3px 0px;
    }
  }
  // img{
  //   width: 45%;
  // }
}


.box-title{
  // font-size: 1rem;
  line-height: 1.3rem;
  // letter-spacing: .2px;
  font-weight: 600;
}
.box-subtitle{
  font-size: .9rem;
  margin-bottom: 15px;
  // color: #464646;
}

.box-subnum{
  font-size: 1rem;
  line-height: 2;
  color: #666;
}
.insure-warp{
  // background: #ffffff;
  // padding-bottom:2.5%;
  position: relative;
  .contrast{
    position: absolute;
    top:.4rem;
    right:1.4rem;
    z-index: 2;
    font-size: .8rem;
    // color: #464646;
    cursor: pointer;
  }
  // .insure-content{
  //   margin: 2.5%;
  // }
}
.toright{
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px 0px rgba(217,217,217,0.5);
  // toright
  // background: linear-gradient(to right ,#ffffff 30%,#e5cccc);
  margin-bottom:2.5%;
  >.flex-box{
    flex-shrink: 0;
    >:first-child{
      width: 60px;
      // background: blue;
      margin-right:10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .box-img-logo{
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius:4px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 6px 0px rgba(141, 141, 141, 0.13);
    
    img{
      max-width: 50px;
      max-height: 50px;
      vertical-align: middle;
    }
  }
  .box-subtitle{
    // line-height: 3;
    margin-bottom: 0rem;
    margin-top:8px;
    color: #464646;
  }
  .box-bs{
    position: absolute;
    right: 1rem;
    // top:1rem;
    color: #ffffff;
    border-radius: 1rem;
    background: #80a9c6;
    padding: 2px 10px;
    font-size: .6rem;
  }
  .box-more{
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 2;
    font-size: 0.8rem;
    cursor: pointer;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
  }
}
.toright-zjx{
  // background: linear-gradient(to right ,#ffffff 30%,#bedaed);
  .box-bs{
    background: #002D91;
  }
}
.eam-box{
  position: relative;
  margin-bottom: 2.5%;
  .box-subtitle{
    font-family: PingFangSC-Regular, PingFang SC;
    margin-bottom: 0rem;
    font-size: .8rem;
    margin-bottom: 20px;
  }
  .eam-banner{
    // height: 130px;
    border-radius:8px;
    color: #fff;
    background-image: url('../assets/img/eam-banner.png') ;
    background-size:cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding:7% 4%;
  }
  .insure-more{
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    font-size: .8rem;
    // position: absolute;
    // bottom: 1.5rem;
    // right: 1.5rem;
    // z-index: 2;
    // font-size: 0.8rem;
    // color: #666;
    // cursor: pointer;
  }
}
.box-num{
  margin-top:5%
}
.flex-between{
  .defined-btn{
    border-radius: 20px;
    width: 100px;
  }
}
.top-top {
  margin:8% 4% 2%;
  justify-content: space-between;
  .top-left{
    img{
      height: 30px;
    }
  }
  .top-right{
    img{
      height: 20px;
    }
  }
}
.hold-box{
  justify-content: space-between;
  align-items: center;
  .hold-left{
    div{
      margin-right:5px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      img{
        height: 30px;
        margin-right:8px;
      }
    }
    align-items: center;
  }
  .hold-right{
    font-size: .9rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
  }
}
.dqlc-top{
  height: 46px;
  background: linear-gradient(90deg, #FFFFFF 0%, #D5E6FE 100%);
  border-radius: 8px;
  position: relative;
  align-items: center;
  .dqlc-top-title{
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    margin-right:4px;
  }
  .dqlc-top-subtitle{
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #464646;
    font-size: .9rem;
  }
  .money-icon{
    position: absolute;
    right: 10px;
    height: 38px;
    top:3px
  }
  .money-en{
    position: absolute;
    right: 23px;
    top: 16px;
    color: #fff;
  }
}
.dqlc-middle{
  margin-top:30px
}
.buy-btn{
  // width: 97px;
  // height: 28px;
  background: #002D91;
  border-radius: 17px;
  color: #fff;
  padding: 2% 6%;
  align-items: center;
  img{
    height: 20px;
    margin-right: 5px;
  }
}
.low-box{
  align-items: center;
  color: #32BF10 ;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  img{
    height: 1.1rem;
    margin-right: 4px;
  }
}
.dqlc-bottom{
  margin-top:20px
}
</style>