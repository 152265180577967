var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("div", { staticClass: "top-content" }, [
        _c("div", { staticClass: "top-top flex-box" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "top-right" },
            [
              _c(
                "el-badge",
                { staticClass: "item", attrs: { "is-dot": _vm.dot > 0 } },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/img/home/msg.png") },
                    on: { click: _vm.tongBtn },
                  }),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "box-items hold-box flex-box",
            on: { click: _vm.holdRouter },
          },
          [
            _c("div", { staticClass: "flex-box hold-left" }, [
              _c("div", [
                _c("img", {
                  attrs: { src: require("@/assets/img/home/sf.png") },
                }),
              ]),
              _c("div", { staticClass: "theme-color" }, [_vm._v("我的持有")]),
            ]),
            _c("div", { staticClass: "hold-right theme-color" }, [
              _vm._v("查看详情 >"),
            ]),
          ]
        ),
        _c("div", { staticClass: "title" }, [_vm._v("定期理财")]),
        _c(
          "div",
          { staticClass: "box-items box-border", on: { click: _vm.stagesBuy } },
          [
            _vm._m(1),
            _c("div", { staticClass: "flex-box dqlc-middle" }, [
              _c("div", { staticStyle: { "margin-right": "10%" } }, [
                _c("div", { staticClass: "box-num flex-box" }, [
                  _c("div", { staticClass: "flex-box box-percent" }, [
                    _c("div", [_vm._v(_vm._s(_vm.stagesMin))]),
                    _c("div", { staticClass: "percent" }, [_vm._v("%")]),
                  ]),
                  _c("div", [_vm._v("-")]),
                  _c("div", { staticClass: "flex-box box-percent" }, [
                    _c("div", [_vm._v(_vm._s(_vm.stagesMax))]),
                    _c("div", { staticClass: "percent" }, [_vm._v("%")]),
                  ]),
                ]),
                _c("div", { staticClass: "box-subnum" }, [
                  _vm._v("预期年化收益"),
                ]),
              ]),
              _vm._m(2),
            ]),
            _vm._m(3),
          ]
        ),
        _c("div", { staticClass: "insure-warp" }, [
          _c(
            "div",
            {
              staticClass: "contrast theme-color",
              on: { click: _vm.InsuranceComparison },
            },
            [_vm._v("险种对比 >")]
          ),
          _c("div", { staticClass: "title" }, [_vm._v("香港保险")]),
          _c(
            "div",
            { staticClass: "insure-content box-border" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.filterType },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("el-tab-pane", { attrs: { label: "全部", name: "all" } }),
                  _c("el-tab-pane", {
                    attrs: { label: "重疾险", name: "zjx" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "储蓄险", name: "cxx" },
                  }),
                ],
                1
              ),
              _vm._l(_vm.bxtypesList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "box-items-line toright",
                    class: item.type == "重疾险" ? "toright-zjx" : "",
                    on: {
                      click: function ($event) {
                        return _vm.bxMore(item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "box-bs" }, [
                      _vm._v(_vm._s(item.type)),
                    ]),
                    _c("div", { staticClass: "flex-box" }, [
                      _c("div", { staticClass: "box-left-logo" }, [
                        _c("div", { staticClass: "box-img-logo" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/" +
                                item.subtitle +
                                ".png"),
                            },
                          }),
                        ]),
                      ]),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "box-title",
                            staticStyle: { "margin-top": "5px" },
                          },
                          [_vm._v(_vm._s(item.title))]
                        ),
                        _c("div", { staticClass: "box-subtitle" }, [
                          _vm._v(_vm._s(item.subtitle)),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "box-more theme-color" }, [
                      _vm._v("查看详情 >"),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "eam-box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("EAM私人银行服务")]),
          _c(
            "div",
            { staticClass: "eam-banner", on: { click: _vm.insureDetail } },
            [
              _c("div", { staticClass: "box-title" }, [
                _vm._v("私行中的“高级定制”"),
              ]),
              _c("div", { staticClass: "box-subtitle" }, [
                _vm._v("一站式私人财富管理"),
              ]),
              _c("div", { staticClass: "insure-more" }, [_vm._v("查看详情 >")]),
            ]
          ),
        ]),
      ]),
      _c("div", {
        ref: "navBtnMain",
        staticClass: "menuIcon",
        class: { donghua: _vm.donghua },
        style: "left:" + _vm.moveX + "px" + ";top:" + _vm.moveY + "px",
        on: {
          click: function ($event) {
            _vm.SidebarShow = true
          },
          touchstart: _vm.drag_start,
          touchmove: function ($event) {
            $event.preventDefault()
            return _vm.drag_hmove.apply(null, arguments)
          },
          touchend: _vm.drag_end,
        },
      }),
      _vm.SidebarShow
        ? _c("Sidebar", {
            on: {
              closeSideBar: function ($event) {
                _vm.SidebarShow = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-left" }, [
      _c("img", { attrs: { src: require("@/assets/img/mu-logo.png") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box dqlc-top" }, [
      _c("div", { staticClass: "dqlc-top-title" }, [_vm._v("亚洲分期")]),
      _c("div", { staticClass: "dqlc-top-subtitle" }, [
        _vm._v("精选优质消费类资产"),
      ]),
      _c("img", {
        staticClass: "money-icon",
        attrs: { src: require("@/assets/img/home/money.png") },
      }),
      _c("div", { staticClass: "money-en" }, [_vm._v("$")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "box-num flex-box" }, [
        _c("div", { staticClass: "flex-box box-percent" }, [
          _c("div", { staticStyle: { color: "#000" } }, [_vm._v("$1,000")]),
          _c("div", { staticStyle: { color: "#fff" } }, [_vm._v("%")]),
        ]),
      ]),
      _c("div", { staticClass: "box-subnum" }, [_vm._v("起投金额")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-box flex-between dqlc-bottom" }, [
      _c(
        "div",
        { staticClass: "low-box flex-box", staticStyle: { width: "60%" } },
        [
          _c("img", { attrs: { src: require("@/assets/img/home/low.png") } }),
          _c("div", [_vm._v("中低风险")]),
        ]
      ),
      _c("div", { staticClass: "buy-btn flex-box" }, [
        _c("img", { attrs: { src: require("@/assets/img/home/buy.png") } }),
        _c("div", [_vm._v("立即购买")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }